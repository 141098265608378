import * as ExperimentActions from '@redux/experiments';
import { selectAssignment } from '@redux/experiments/selectors';
import { betterImageAssignmentSelector } from '@redux/experiments/selectors/better-image';
import { newExpandedStartingPriceAssignmentSelector } from '@redux/experiments/selectors/expanded-starting-price';
import { expectedValueSortSelector } from '@redux/experiments/selectors/expected-value-sort';
import { isNewBadgeAssignmentSelector } from '@redux/experiments/selectors/is-new-badge';
import { mobileMapViewAssignmentSelector } from '@redux/experiments/selectors/mobile-map-view';
import { newHeartAssignmentSelector } from '@redux/experiments/selectors/new-heart';
import { newSortPillAssignmentSelector } from '@redux/experiments/selectors/new-sort-pill';
import { newStartingPriceAssignmentSelector } from '@redux/experiments/selectors/new-starting-price';
import { quickResponderAssignmentSelector } from '@redux/experiments/selectors/quick-responder';
import { recentlyViewedAssignmentSelector } from '@redux/experiments/selectors/recently-viewed';
import { semanticSearchV2Selector } from '@redux/experiments/selectors/semantic-search-v2';
import { useAppSelector } from '@redux/hooks';
import { experiments, tkHost } from '@settings';
import React, { type FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { type RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import buildLinkedDataFromApiData from '../../../helpers/linkedData';
import MetaContent from '../../components/shared/HelmetHeader/HelmetHeader';
import { RFQModalContextProvider } from '../../contexts/RFQModalContext/RFQModalContext';
import { WeddingsContextProvider } from '../../contexts/WeddingsContext/WeddingsContext';
import { setCookieLocation } from '../../utils/cookie/location';
import WrappedVendorsSearch from './containers/WrappedVendorsSearch';
import * as util from './util';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

type Props = StateProps & DispatchProps & RouteComponentProps;

const VendorsSearchContainer: FunctionComponent<Props> = (props) => {
	const {
		category,
		categoryFilters,
		vendorLocation,
		history: {
			location: { pathname },
		},
		isPermitted,
		reportServerSideExperiment,
		seo,
	} = props;

	const seoCategory = util.categoryText(category, categoryFilters);

	const reorderAllFiltersAssignment = useAppSelector((state) =>
		selectAssignment(state, ['reorderAllFilters']),
	);
	const tkNoContentBlurbsAssignment = useAppSelector((state) =>
		selectAssignment(state, ['tkNoContentBlurbs']),
	);
	const refreshResultsAssignment = useAppSelector((state) =>
		selectAssignment(state, ['refreshResults']),
	);

	const betterImageAssignment = useAppSelector((state) =>
		betterImageAssignmentSelector(state),
	);

	const newHeartAssignment = useAppSelector((state) =>
		newHeartAssignmentSelector(state),
	);

	const mobileMapViewAssignment = useAppSelector((state) =>
		mobileMapViewAssignmentSelector(state),
	);

	const quickResponderAssignment = useAppSelector((state) =>
		quickResponderAssignmentSelector(state),
	);

	const newSortPillAssignment = useAppSelector((state) =>
		newSortPillAssignmentSelector(state),
	);

	const newStartingPriceAssignment = useAppSelector((state) =>
		newStartingPriceAssignmentSelector(state),
	);

	const newExpandedStartingPriceAssignment = useAppSelector((state) =>
		newExpandedStartingPriceAssignmentSelector(state),
	);

	const expectedValueSortAssignment = useAppSelector((state) =>
		expectedValueSortSelector(state),
	);

	const recentlyViewedAssignment = useAppSelector((state) =>
		recentlyViewedAssignmentSelector(state),
	);

	const semanticSearchV2Assignment = useAppSelector((state) =>
		semanticSearchV2Selector(state),
	);

	const isNewBadgeAssignment = useAppSelector((state) =>
		isNewBadgeAssignmentSelector(state),
	);

	useEffect(() => {
		window.UnionConsentManagement.onConsentedToPersonalisation(() => {
			setCookieLocation(vendorLocation);
		});

		if (reorderAllFiltersAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log(
				'Reorder all filters Assignment: ',
				reorderAllFiltersAssignment,
			);
			reportServerSideExperiment(experiments.reorderAllFilters);
		}

		if (tkNoContentBlurbsAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log(
				'TK No Content Blurbs Assignment: ',
				tkNoContentBlurbsAssignment,
			);
			reportServerSideExperiment(experiments.tkNoContentBlurbs);
		}

		if (refreshResultsAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('Refresh results Assignment: ', refreshResultsAssignment);
			reportServerSideExperiment(experiments.refreshResults);
		}

		if (betterImageAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('Better Image Assignment: ', betterImageAssignment);
			reportServerSideExperiment(experiments.betterImage);
		}

		if (newHeartAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('New Heart Assignment: ', newHeartAssignment);
			reportServerSideExperiment(experiments.newHeart);
		}

		if (mobileMapViewAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('Mobile Map View Assignment: ', mobileMapViewAssignment);
			reportServerSideExperiment(experiments.mobileMapView);
		}

		if (quickResponderAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('Quick Responder Assignment: ', quickResponderAssignment);
			reportServerSideExperiment(experiments.quickResponder);
		}

		if (newSortPillAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('New Sort Pill Assignment: ', newSortPillAssignment);
			reportServerSideExperiment(experiments.sortPill);
		}

		if (newStartingPriceAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log(
				'New starting price assignment: ',
				newStartingPriceAssignment,
			);
			reportServerSideExperiment(experiments.newStartingPrice);
		}

		if (newExpandedStartingPriceAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log(
				'New expanded (REC, VID, FLO) starting price assignment: ',
				newExpandedStartingPriceAssignment,
			);
			reportServerSideExperiment(experiments.REC_VID_FLO_startingPrice);
		}

		if (expectedValueSortAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log(
				'Expected value sort assignment: ',
				expectedValueSortAssignment,
			);
			reportServerSideExperiment(experiments.expectedValue);
		}

		if (recentlyViewedAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('Recently viewed assignment: ', recentlyViewedAssignment);
			reportServerSideExperiment(experiments.recentlyViewed);
		}

		if (semanticSearchV2Assignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log(
				'Semantic search v2 assignment: ',
				semanticSearchV2Assignment,
			);
			reportServerSideExperiment(experiments.semanticSearchV2);
		}

		if (isNewBadgeAssignment) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log('Is New Badge Assignment: ', isNewBadgeAssignment);
			reportServerSideExperiment(experiments.isNewBadge);
		}
	}, [
		betterImageAssignment,
		mobileMapViewAssignment,
		newExpandedStartingPriceAssignment,
		newHeartAssignment,
		refreshResultsAssignment,
		reorderAllFiltersAssignment,
		reportServerSideExperiment,
		tkNoContentBlurbsAssignment,
		vendorLocation,
		quickResponderAssignment,
		newSortPillAssignment,
		newStartingPriceAssignment,
		expectedValueSortAssignment,
		recentlyViewedAssignment,
		semanticSearchV2Assignment,
		isNewBadgeAssignment,
	]);

	return (
		<>
			<RFQModalContextProvider>
				<WeddingsContextProvider>
					<MetaContent
						buildLinkedData={buildLinkedDataFromApiData}
						canonical={seo.canonicalUrl}
						description={`Learn more about ${seoCategory.toLowerCase()} in ${
							vendorLocation.city
						} on The Knot.
            Find, research and contact wedding professionals on The Knot, featuring reviews
            and info on the best wedding vendors.`}
						landingPage="listings"
						next={seo.nextUrl}
						ogImage={util.ogImage(category.code)}
						pathname={`${tkHost}${pathname}`}
						prev={seo.previousUrl}
						title={util.pageTitle(vendorLocation, seoCategory, category.code)}
						noIndex={!isPermitted}
					/>
					<WrappedVendorsSearch />
				</WeddingsContextProvider>
			</RFQModalContextProvider>
		</>
	);
};

export const mapStateToProps = (state: Redux.State) => ({
	category: state.category,
	categoryFilters: state.filters.categoryFilters,
	isPermitted: state.search.isPermitted,
	seo: state.seo,
	vendorLocation: state.location,
});

export const mapDispatchToProps = {
	reportServerSideExperiment: ExperimentActions.reportServerSideExperiment,
};

const enhance = compose(
	connect<StateProps, DispatchProps>(mapStateToProps, mapDispatchToProps),
	withRouter,
);

export default enhance(VendorsSearchContainer);
